import React from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { RefreshGrid, RefreshCell, GRID_DEFAULT_CONFIG } from 'src/components/layout/Grid';
import { asSubsection } from 'src/components/layout/Subsection';
import { FluidText } from 'src/components/common/FluidText';
import { PricingCards } from 'src/components/common/PricingCards';
import { cardsContainer_, disclaimer_ } from './ProductPricingCards.module.scss';

const PRODUCT_CONFIG = {
  pdp: {
    variant: 'pdp',
    theme: 'evo',
  },
  tax: {
    variant: 'tax',
    theme: 'evo',
  },
};

const getProductConfig = product => {
  if (product === 'tax') return { ...{ product }, ...PRODUCT_CONFIG.tax };
  return { ...{ product }, ...PRODUCT_CONFIG.pdp };
};

const ProductPricingCards = asSubsection('ProductPricingCards')(
  ({ heading = 'Choose a plan to help you stick to your plan', product = 'managed-investing', className, disclaimer, ...rest }) => {
    return (
      <div className={className} {...rest}>
        <RefreshGrid xs={{ gap: 0 }} lg={{ gap: GRID_DEFAULT_CONFIG.gap }}>
          <RefreshCell xs={{ width: 12 }} lg={{ width: 7 }}>
            {isString(heading) ? (
              <FluidText type="h2" min="ws-display-md-serif" max="ws-display-xl-serif">
                {heading}
              </FluidText>
            ) : (
              heading
            )}
          </RefreshCell>
        </RefreshGrid>
        <div className={cardsContainer_}>
          <PricingCards {...getProductConfig(product)} />
        </div>
        {disclaimer && (
          <FluidText type="p" all="ws-text-sm" className={disclaimer_}>
            {disclaimer}
          </FluidText>
        )}
      </div>
    );
  }
);

ProductPricingCards.propTypes = {
  product: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

ProductPricingCards.displayName = 'ProductPricingCards';

export { ProductPricingCards };
