import React from 'react';
import gsap from 'gsap';
import PropTypes from 'prop-types';
import { REFRESH_BREAKPOINTS, classNames } from 'src/utils/css';
import { formatCurrency } from 'src/utils/formatters';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { RefreshCell, RefreshGrid } from 'src/components/layout/Grid';
import { FluidText } from 'src/components/common/FluidText';
import { AnimatedScrollBlock } from 'src/tapestry/animation/animated-scroll-block';
import { ProductSection } from './ProductSection/ProductSection.component';
import { translations } from './TiersTable.translations';
import {
  wrapper_,
  defaultBackgroundColor_,
  innerWrapper_,
  stickyVariantDefault_,
  stickyVariantSecondary_,
  productWrapperVariantDefault_,
  productWrapperVariantSecondary_,
  productHeadingScrollDuplicate_,
  tierCurrency_,
  tierCurrencyLabel_,
  tierNameGrid_,
  tierName_,
  tierNameLast_,
  tierHeading_,
  tierHeadingSuffixContainer_,
  topStickyBorder_,
  stickyHiderT_,
  tierHeadingContainer_,
} from './TiersTable.module.scss';

const VARIANTS = {
  default: {
    id: 'default',
    sticky: stickyVariantDefault_,
    productTableContainer: productWrapperVariantDefault_,
    headingTravelDistanceY: 50,
  },
  secondary: {
    id: 'secondary',
    sticky: stickyVariantSecondary_,
    productTableContainer: productWrapperVariantSecondary_,
    headingTravelDistanceY: 36,
  },
};

const Heading = ({ title, currency, suffix, className, locale }) => (
  <div className={classNames(tierName_, className)}>
    <FluidText className={tierHeading_} min="ws-text-lg-medium" max="ws-text-3xl-medium">
      {title}
    </FluidText>
    {(currency || suffix) && (
      <div className={tierHeadingSuffixContainer_}>
        {currency && (
          <FluidText
            className={tierCurrency_}
            min="ws-text-xs-medium"
            max="ws-text-md-medium"
          >
            {`${formatCurrency(currency, {
              locale,
            })}`}
          </FluidText>
        )}
        {suffix && (
          <FluidText className={tierCurrencyLabel_} min="ws-text-xs" max="ws-text-md">
            {`${' '}${suffix}`}
          </FluidText>
        )}
      </div>
    )}
  </div>
);

const TierHeadings = ({ headings = {} }) => {
  const { locale } = useTranslationContext();
  const [tierOne, tierTwo, tierThree] = headings;

  return (
    <>
      <RefreshCell xs={{ width: 4, columnGap: 0 }} lg={{ width: 2, left: 6 }}>
        <Heading {...tierOne} locale={locale} />
      </RefreshCell>
      <RefreshCell xs={{ width: 4 }} lg={{ width: 2, left: 8 }}>
        <Heading {...tierTwo} locale={locale} />
      </RefreshCell>
      <RefreshCell xs={{ width: 4 }} lg={{ width: 2, left: 10 }}>
        <Heading {...tierThree} locale={locale} className={tierNameLast_} />
      </RefreshCell>
    </>
  );
};

const DEFAULT_TABLE_DATA = [
  [
    {
      heading: 'First table heading',
      subheading: 'First table subheading',
      rows: [
        {
          title: 'First row - cell one',
          tooltip: `tooltip`,
          core: true,
          premium: 'true',
          generation: 'number %',
        },
      ],
    },
  ],
];
const DEFAULT_TIER_IDS = ['core', 'premium', 'generation'];
const DEFAULT_HEADINGS = [{ title: 'Core' }, { title: 'Premium' }, { title: 'Generation' }];

const TiersTable = ({
  background = defaultBackgroundColor_,
  tableData = DEFAULT_TABLE_DATA,
  tierIds = DEFAULT_TIER_IDS,
  headings = DEFAULT_HEADINGS,
  variant = VARIANTS.default.id,
  disableMobileAccordion,
}) => {
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);

  const timeline = (config, ref) => {
    const yDistance = VARIANTS[variant].headingTravelDistanceY / 2;
    return {
      [`(min-width: ${
        REFRESH_BREAKPOINTS.lg
      }px) and (prefers-reduced-motion: no-preference)`]: () => {
        const tl = gsap.timeline(config);
        tl.to({ dummy: 0 }, { duration: 1, dummy: 1 }, 0);
        const el = document.querySelector(`[data-heading-index="${ref.index}"]`);
        tl.fromTo(
          el,
          { y: yDistance },
          {
            duration: 0.25,
            autoAlpha: 1,
            y: 0,
          },
          0
        );
        tl.fromTo(
          el,
          { y: 0 },
          {
            duration: 0.25,
            autoAlpha: 0,
            y: -yDistance,
          },
          0.75
        );

        return () => {
          gsap.set(el, { autoAlpha: 0 });
        };
      },
    };
  };

  return (
    <div className={wrapper_}>
      <div className={innerWrapper_}>
        <div className={VARIANTS[variant].sticky}>
          {/* fake overflow:hidden effect uses mulitple divs to cover layers under the sticky element  */}
          <div data-bg-faker="true" className={stickyHiderT_} style={{ background }} />
          <div className={topStickyBorder_} style={{ background }} />
          <RefreshGrid className={tierNameGrid_} all={{ columnGap: 0 }}>
            {/* duplicate product headings container for the magnify text scroll effect in the upper sticky */}
            <RefreshCell
              className={productHeadingScrollDuplicate_}
              xs={{ width: 0, left: 1, columnGap: 0 }}
              lg={{ width: 4, left: 2 }}
              aria-hidden="true"
            >
              {tableData.map((product, index) => {
                return (
                  <div
                    key={`menuHeading-${product.heading}`}
                    className={tierHeadingContainer_}
                    data-heading-index={`${index}`}
                  >
                    <FluidText
                      type="h3"
                      className={tierHeading_}
                      min="ws-text-2xl-medium"
                      max="ws-text-3xl-medium"
                    >
                      {product.heading}
                    </FluidText>
                  </div>
                );
              })}
            </RefreshCell>
            <TierHeadings headings={headings} />
          </RefreshGrid>
        </div>
        {tableData.map((product, index) => {
          return (
            <AnimatedScrollBlock
              key={`tiersScrollBlock-${product.heading}`}
              startPosition="top top+=100"
              endPosition="bottom top+=100"
              timeline={timeline}
              timelineDependencies={{ index }}
              className={VARIANTS[variant].productTableContainer}
            >
              <ProductSection
                tierIds={tierIds}
                heading={product.heading}
                pill={product.pill}
                subheading={product.subheading}
                rows={product.rows}
                index={index}
                variant={variant}
                disableMobileAccordion={disableMobileAccordion}
              />
            </AnimatedScrollBlock>
          );
        })}
      </div>
    </div>
  );
};

export { TiersTable };

TiersTable.propTypes = {
  variant: PropTypes.oneOf([VARIANTS.default.id, VARIANTS.secondary.id]),
  background: PropTypes.string,
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      subheading: PropTypes.string,
      rows: PropTypes.arrayOf(
        PropTypes.shape({ title: PropTypes.string, tooltip: PropTypes.string })
      ),
    })
  ),
  tierIds: PropTypes.arrayOf(PropTypes.string),
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      currency: PropTypes.number,
      suffix: PropTypes.string,
    })
  ),
};
